/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-danger */
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { isBrowser } from "../services/auth"
import { getQueryVariable } from "../lib/util"
import SEO from "../components/SEO/seo"
import Layout from "../zzz/layout"
import Checkout from "../components/Checkout/Checkout"
import CheckoutLayout from "../components/Checkout/Layout"

const CheckoutPage = ({ location }) => {
  // Get SEO through static query
  const data = useStaticQuery(PAGE)
  const seo = data.page.seo

  let orderId = undefined
  if (isBrowser()) {
    orderId = getQueryVariable("orderid")
  }

  // Skip tracking because we perform tracking manually in Checkout
  return (
    <Layout location={location} blank skipReSciTracking>
      <CheckoutLayout>
        <SEO title="Checkout" location={location} post_id={19} seo={seo} />
        <Checkout
          location={location}
          givenOrderId={orderId}
          givenTabIdx={orderId && 1}
        />
      </CheckoutLayout>
    </Layout>
  )
}

export default CheckoutPage

const PAGE = graphql`
  {
    page: localWpGraphQlPages(pageId: { eq: 19 }) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
    }
  }
`
